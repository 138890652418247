<template>
  <header class="header">
    <nav id="nav-pc">
      <router-link
        :to="'/' + this.$route.params.lang + item.Link"
        :target="item.Target"
        :class="{ 'logo' : item.Title=='首頁', 'menu-item' : item.Title!=='首頁' }"
        v-for="(item, index) in headerMenu"
        :key="item.Title"
        @click="clickHandle(index,item.Link); isClicked==index"
        @mouseenter="isHovering = index"
        @mouseleave="isHovering = -1"
        >
          <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/logo-hcilove.svg" v-if="item.Title=='首頁'" />
          <img :src="item.ImgHover" v-if="isHovering==index || isClicked==index" />
          <img :src="item.Img" v-else-if="isHovering!==index" />
      </router-link>
    </nav>

    <div id="nav-mobile">
      <div class="menubar">
        <a @click="show = true; getViewHeight()" class="menubar-toggler"
          ><i class="icon-menubar-toggler"></i
        ></a>
        <router-link :to="'/' + this.$route.params.lang + '/home'"
          ><img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/logo-hcilove-h.svg"
        /></router-link>
      </div>
      <div class="menubar-overlay" v-if="show" :class="{ show: show == true }" @click="show = false">
        <div class="menubar-slide">
          <div class="menubar-close" @click="show = false">
            <i class="icon icon-close"></i>
          </div>
          <header class="menu-header">
            <router-link :to="'/' + this.$route.params.lang +'/home'">
              <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/logo-hcilove-mobile.svg" />
            </router-link>
          </header>
          <div class="menu-body">
            <nav class="d-flex flex-column align-items-center">
              <template v-for="item in headerMenu" :key="item.ID">
                <router-link
                  :to="'/' + this.$route.params.lang + item.Link"
                  :target="item.Target"
                  class="menu-item"
                  v-if="item.Link !== '/home'"
                >
                  <img :src="item.MImg" />
                </router-link>
              </template>
            </nav>
          </div>
          <footer class="menu-footer">
            <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-banner-primary.svg" class="img-fluid" style="margin-bottom: -7px;" />
            <div class="bg-primary">
            <nav class="footer-links">
              <router-link
                :to="'/' + this.$route.params.lang + item.Link"
                v-for="item in footerMenu"
                :key="item.ID"
                >{{ item.Title }}</router-link
              >
            </nav>

            <div class="social-media">
              <a href="https://www.facebook.com/hci17611096/" target="_blank">
                <i class="icon icon-social-green-fb"></i>
              </a>
              <a href="mailto:hcilove@hci.org.tw">
                <i class="icon icon-social-green-email"></i>
              </a>
              <router-link :to="'/' + this.$route.params.lang + '/contact'">
                <i class="icon icon-social-green-talk"></i>
              </router-link>
            </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";

import device from "current-device";

export default {
  data() {
    return {
      show: false,
      headerMenu: [],
      footerMenu: [],
      menuHover: false,
      active: false,
      isHovering: -1,
      isChecked: 0,
      isClicked: 0,
      vh: 0,
    }
  },
  created() {
    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"HciMenu","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.headerMenu = res.data.data;
    });

    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"HciFtMenu","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.footerMenu = res.data.data;
    });
  },
  mounted() {
    console.log(device.desktop());
  },
  updated() {
    this.show ? document.body.classList.add("lock-position") : document.body.classList.remove("lock-position");
  },
  methods: {
    clickHandle(val1,val2) {
      this.isClicked = val1;
      this.active = true;
      console.log("vv", val1, val2, this.active, this.currPageUrl);
    },
    getViewHeight(){
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    }
  },
};
</script>

<style lang="scss">
.lock-position{
  position: relative;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}
</style>>