<template>
  <div class="donate__container">
    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'donate'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <template #title
          ><h1 class="bannerbox__title">{{ item.Title }}</h1></template
        >
        <template #desc
          ><p>{{ item.SubTitle }}</p></template
        >
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-primary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article class="bg-primary">
      <!-- SECTION_1 -->
      <section class="sec py-0 pt-3">
        <div class="container">
          <h2 class="sec__title">捐款資訊</h2>
          <div class="sec__content">
            <div class="donate-bubble bubble">
              <div class="bubble-overlay">
                <ul class="donate-info">
                  <li class="text-center text-sm-start">
                    <span>銀行</span><br class="d-block d-sm-none" />
                    <span>國泰世華商業銀行<br class="d-block d-sm-none" />光復分行
                    (<span class="font-enUS">ATM</span>轉帳代碼：<span class="font-enUS">013</span>)</span>
                  </li>
                  <li class="text-center text-sm-start">
                    <span>戶名</span><br class="d-block d-sm-none" />
                    <span>財團法人許潮英社會福利<br
                      class="d-block d-sm-none"
                    />慈善事業基金會</span>
                  </li>
                  <li class="text-center text-sm-start">
                    <span>帳號</span><br class="d-block d-sm-none" />
                    <span class="font-enUS">016-03-003788-3</span>
                  </li>
                </ul>
                <p class="mark-light">
                  *請捐款人於匯款當日<br
                    class="d-block d-sm-none"
                  />來信或來電通知，感謝您!
                </p>
                <p class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                  <span class="px-3">電話：<span class="font-enUS">0986-598-698</span> 王小姐</span>
                  <span class="font-enUS px-3">Email：<a href="mailto:hcilove@hci.org.tw" style="text-decoration:underline;">hcilove@hci.org.tw</a></span>
                </p>
              </div>

              <img
                src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-donate-bubble-lg.svg"
                class="img-fluid d-none d-sm-block"
              />
              <img
                src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-donate-bubble-sm.svg"
                class="img-fluid d-block d-sm-none resize320"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION_2 -->
      <section class="sec pt-4">
        <div class="container">
          <h2 class="sec__title mt-4 mt-xs-0 pt-2">捐款資訊查詢</h2>
          <div class="sec__content">
            <div class="row justify-content-center">
              <div class="col-md-9">
                <p class="sec__text mb-0">
                  親愛的捐款人您好，依財團法人法第25條規定，除捐贈者事先表示反對外，本會需主動公開捐贈者姓名及金額。如不同意公開於愛心芳名錄，請與我們連繫。若還有任何捐款相關問題，與我們聯繫，謝謝!
                </p>
              </div>
            </div>

            <tabs-slides :data_Y="donateList_Y" v-on:getYear="getYear">
              <template #activetab>
                <table-rwd
                  :fileList="donateList"
                  :itemClass="donateListClass"
                  :itemName="'DonateItem'"
                ></table-rwd>
              </template>
            </tabs-slides>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import TableRwd from "@/components/TableRwd.vue";
import TabsSlides from "@/components/TabsSlides.vue";

export default {
  components: {
    BannerSingle,
    TableRwd,
    TabsSlides,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      pagesBanner: [],
      activetab: null,
      donateListClass: [],
      donateList_Y: [],
      donateList: [],
      donateListAll: [],
      // sortBy: null,
      // priority: null,
      // ASC: null,
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
    });

    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"Donate","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.donateList = res.data.data;
      this.donateListClass = res.data.DonateItem;

      let DateTmp = [];
      this.donateList.forEach((v, k) => {
        DateTmp[k] = v.Date.slice(0, 4);
      });
      this.donateList_Y = DateTmp.filter(
        (va, vb) => DateTmp.indexOf(va) === vb
      ).reverse();
      //console.log();

      this.donateListAll = this.donateList;
      this.getYear(this.donateList_Y[0]);
    });
  },
  // computed: {
  //   newarray: function () {
  //     var vm = this;
  //     return this.donateList.sort(function (a, b) {
  //         var a = a[vm.priority]
  //         var b = b[vm.priority]
  //         return (a === b ? 0 : a > b ? vm.ASC * 1 : vm.ASC * -1)
  //     });
  //   }
  // },
  methods: {
    getYear(getYear) {
      this.donateList = this.donateListAll.filter(
        (item) => item.Date.slice(0, 4) == getYear
      );
      this.activetab = getYear;
      // console.log("YYYYYYY", getYear, this.donateList);
    },
  },
};
</script>

<style lang="scss">
@media (max-width:320px) {
  .resize320 {
    transform: scale(1.1,1.2);
  }
}

.donate-info {
  margin-top: 1.4rem;
  @media (max-width:992px) {
    margin-bottom: 0;
  }
  @media (max-width:576px) {
    margin: 0;
  }
  li {
    margin-bottom: 1rem;
    @media (max-width:992px) {
      margin-bottom: 0;
    }
    @media (max-width:576px) {
      padding-bottom: 1rem;
    }
    span:first-child {
      font-size: 22px;
      font-weight: 700;
      padding: 0 2rem;
      @media (max-width:992px) {
        font-size: 20px;
      }
    }
    span:last-child {
      font-size: 20px;
      @media (max-width:992px) {
        font-size: 18px;
      }
      @media (max-width:576px) {
        font-size: 16px;
      }
    }
    &:last-child {
      span:last-child {
        font-size: 26px;
        @media (max-width:576px) {
          font-size: 22px;
        }
      }
    }
  }
}
</style>