<template>
  <div class="contact__container">
    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'contact'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <template #title
          ><h1 class="bannerbox__title">{{ item.Title }}</h1></template
        >
        <template #desc
          ><p>{{ item.SubTitle }}</p></template
        >
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article class="bg-secondary">
      <!-- SECTION_1 -->
      <section class="sec">
        <div class="sec__content container">
          <div class="form__block">
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-body-deco.svg"
              class="deco bg-deco-start d-none d-md-block"
            />
            <img
              src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-body-deco.svg"
              class="deco bg-deco-end d-none d-md-block"
            />
            <Form
              id="contactForm"
              @submit="handleSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
              ref="myForm"
            >
              <!-- <pre>{{errors}}</pre> -->
              <div class="form__card bg-middle shadow">
                <div class="mb-3 row">
                  <label
                    for="inputName"
                    class="col-md-3 col-form-label label-title"
                    >姓名</label
                  >
                  <div class="col-md-9">
                    <Field
                      id="inputName"
                      name="inputName"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.inputName }"
                      placeholder="請輸入名稱"
                      v-model="contactFormData.Name"
                    />
                    <ErrorMessage name="inputName" class="text-danger ps-4" />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="inputPhone"
                    class="col-md-3 col-form-label label-title"
                    >聯絡電話</label
                  >
                  <div class="col-md-9">
                    <Field
                      id="inputPhone"
                      name="inputPhone"
                      type="tel"
                      class="form-control"
                      :class="{ 'is-invalid': errors.inputPhone }"
                      placeholder="請輸入電話"
                      v-model="contactFormData.Phone"
                    />
                    <ErrorMessage name="inputPhone" class="text-danger ps-4" />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="inputEmail"
                    class="col-md-3 col-form-label label-title"
                    >E-mail</label
                  >
                  <div class="col-md-9">
                    <Field
                      id="inputEmail"
                      name="inputEmail"
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': errors.inputEmail }"
                      placeholder="請輸入email"
                      v-model="contactFormData.Email"
                    />
                    <ErrorMessage name="inputEmail" class="text-danger ps-4" />
                  </div>
                </div>

                <!-- <div class="mb-3 row">
                  <label for="" class="col-md-3 col-form-label label-title"
                    >問題類別</label
                  >
                  <div class="col-md-9">
                    <select
                      class="form-select w-100"
                      v-model="contactFormData.QC"
                    >
                      <option disabled value="">請選取問題</option>
                      <option v-for="(V, K) in contactQC" :key="K">
                        {{ V }}
                      </option>
                    </select>
                  </div>
                </div> -->

                <div class="mb-3 row">
                  <label for="" class="col-md-3 col-form-label label-title"
                    >問題類別</label
                  >
                  <div class="col-md-9">
                    <div class="select__wrap">
                      <div class="select__bar" @click="isOpen = !isOpen">
                        {{ contactFormData.QC }}
                      </div>
                      <div class="select__options" v-if="isOpen">
                        <button
                          v-for="(item, K) in contactQC"
                          :key="K"
                          @click="getV($event), (isOpen = !isOpen)"
                          :value="item.ValA"
                        >
                          {{ item.ValB }}
                        </button>
                      </div>
                    </div>
                    <Field v-model="contactFormData.QC" as="select" name="inputQ" class="d-none">
                      <option v-for="(item, K) in contactQC" :key="K" :value="item"></option>
                    </Field>
                    <ErrorMessage
                      name="inputQ"
                      class="text-danger ps-4"
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="inputSubject"
                    class="col-md-3 col-form-label label-title"
                    >主旨</label
                  >
                  <div class="col-md-9">
                    <Field
                      id="inputSubject"
                      name="inputSubject"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.inputSubject }"
                      placeholder="請輸入主旨"
                      v-model="contactFormData.Title"
                    />
                    <ErrorMessage
                      name="inputSubject"
                      class="text-danger ps-4"
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="" class="col-md-3 col-form-label label-title"
                    >訊息內容</label
                  >
                  <div class="col-md-9">
                    <Field
                      rows="4"
                      cols="50"
                      name="inputMsg"
                      class="form-control w-100"
                      as="textarea"
                      :class="{ 'is-invalid': errors.inputMsg }"
                      v-model="contactFormData.Content"
                    />
                    <ErrorMessage name="inputMsg" class="text-danger ps-4" />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                    for="formFile"
                    class="col-md-3 col-form-label label-title no-star"
                    >參考檔案</label
                  >
                  <div class="col-md-9">
                    <div class="input-group flex-column flex-md-row">
                      <input
                        id="formFile"
                        class="form-control me-3"
                        name="fileUpload"
                        type="file"
                        ref="file" 
                        accept="image/png,image/gif,image/jpeg,.pdf"
                        @change="fileSelected"
                      />
                      <input
                        type="btn"
                        class="btn btn-pill btn-primary"
                        @click="$refs.file.click()"
                        value="選擇檔案"
                      />
                      <div
                        @click="$refs.file.click()"
                        :class="[file.name ? 'text-dark' : 'text-muted']"
                        style="
                          font-size: 14px;
                          text-align: left;
                          padding: 0.45rem 1.6rem;
                          position: absolute;
                          cursor: pointer;
                          z-index: 9;
                        "
                        >{{file.name ? file.name : "檔案類型jpg.png.jpeg.gif.pdf"}}</div
                      >
                    </div>
                    <div v-if="image" class="preview mt-2 d-flex align-items-center flex-column">
                      <!-- <small>上傳圖片預覽：</small> -->
                      <img :src="image" />
                      <small v-if="fileUpLoadStatus" :class="[fileUpLoadStatus ? 'text-success' : 'text-danger']">{{fileUpLoadStatus}}</small>
                      <small v-else class="text-dark">上傳中...</small>
                    </div>
                  </div>
                </div>

                <div class="mb-3 row align-items-center">
                  <label for="" class="col-md-3 col-form-label label-title"
                    >方便聯繫時間</label
                  >
                  <div
                    class="
                      col-md-9
                      d-flex
                      justify-content-between
                      flex-column flex-md-row
                      ps-4
                    "
                  >
                    <div
                      class="form-check form-check-inline"
                      v-for="(item, index) in contactTime"
                      :key="index"
                    >
                      <Field
                        class="form-check-input option-input radio"
                        type="radio"
                        name="inputContactTime"
                        :id="'contactTime[' + index + ']'"
                        :value="item"
                        v-model="contactFormData.ContactTime"
                      />
                      <label
                        class="form-check-label font-enUS"
                        :for="'contactTime[' + index + ']'"
                        >{{ item }}</label
                      >
                    </div>
                  </div>
                  <ErrorMessage v-if="submittedShow"
                    name="inputContactTime"
                    class="text-danger offset-sm-3"
                    style="padding-left: 36px;"
                  />
                </div>

                <!-- <div class="mb-3 row">
                  <label
                    for="inputPassword"
                    class="col-md-3 col-form-label label-title"
                    >驗證碼</label
                  >
                  <div class="col-md-9">
                    <div class="input-group position-relative">
                      <input
                        class="form-control"
                        type="text"
                        id=""
                        name="time"
                        value=""
                        placeholder="請輸入驗證碼"
                      />
                      <img
                        src="../assets/images/v-code.jpg"
                        class="verify-code"
                      />
                    </div>
                  </div>
                </div> -->

                <div class="mb-3 row">
                  <label
                    for="inputPassword"
                    class="col-md-3 col-form-label"
                  ></label>
                  <div
                    class="
                      col-md-9
                      d-flex
                      justify-content-md-between
                      flex-column flex-md-row
                      align-items-center
                    "
                  >
                    <span
                      >電話：<span class="font-enUS">0986-598-698</span>
                      王小姐</span
                    >
                    <span
                      ><a href="mailto:hcilove@hci.org.tw" class="font-enUS"
                        >Email：<span style="text-decoration: underline"
                          >hcilove@hci.org.tw</span
                        ></a
                      ></span
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-5">
                <div class="col-lg-6 d-flex justify-content-center">
                  <!-- <input
                    type="submit"
                    class="btn btn-pill btn-light w-100 mx-3"
                    value="清除"
                  /> -->
                  <input
                    type="submit"
                    class="btn btn-pill btn-light mx-3"
                    style="line-height: 0.7;"
                    value="送出"
                    @click="showRadioMsg"
                  />
                </div>
              </div>
            </Form>

            <modal-feedback v-if="isModalOpen" v-model:is-open="isModalOpen">
              <template #text>
                <h3>表單已送出！</h3>
                <p>感謝您的詢問，我們將會盡快與您聯繫!</p>
              </template>
            </modal-feedback>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import ModalFeedback from "@/components/ModalFeedback.vue";

import { Field, Form, ErrorMessage, configure } from "vee-validate";
import * as Yup from "yup";

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

export default {
  components: {
    BannerSingle,
    Field,
    Form,
    ErrorMessage,
    ModalFeedback,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      showFeedback: false,
      submittedShow: false,
      isModalOpen: false,
      isOpen: false,
      image: "",
      file: "",
      fileUpLoadStatus: false,
      fileUpLoadCT: "",
      fileUpLoadCTIMG: "",
      pagesBanner: [],
      contactQC: [],
      contactTime: [],
      QCToEmailTmp:[],
      contactFormData: [
        {
          Title: "",
          Name: "",
          Phone: "",
          Email: "",
          QC: "請選取問題",
          Content: "",
          RefImg: "",
          ContactTime: "",
        },
      ],
      schema: Yup.object().shape({
        inputName: Yup.string().required('姓名為必填欄位').label("姓名"),
        inputPhone: Yup.number().positive().integer().required('電話為必填欄位').label("電話"),
        inputEmail: Yup.string().email("需為有效Email").required('Email為必填欄位').label("Email"),
        inputQ: Yup.string().required("問題類別為必填選").oneOf(["捐款問題","志工招募問題","其他問題"], '問題類別為必選').label("問題類別"),
        inputSubject: Yup.string().required('主旨為必填欄位').label("主旨"),
        inputMsg: Yup.string().required("訊息內容為必填欄位").label("訊息內容"),
        inputContactTime: Yup.string().required("時間為必填欄位").oneOf(["9:00-12:00","13:00-17:00","皆可"], '時間為必填欄位').label("方便聯繫時間"),
      }),
      sendServer: "https://event.xyzprinting.com/Tp/SendMAIL/",
      //sendEmail: "",
      sendName: "hci website",
      sendTrigger: "hci_contact_TW",
    };
  },
  created() {
    var vm = this;
    this.contactFormData.QC = "請選取問題";

    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
    });

    var FD2 = new FormData();
    FD2.append("mode", "cfg");
    FD2.append("data", '{"class":"ContactUs","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      Object.keys(res.data.QC).forEach(function(v){
        if(res.data.QC[v].ValA!==undefined){
          vm.QCToEmailTmp[res.data.QC[v].ValA] = res.data.QC[v].ValC;
        }
      });
      this.contactQC = res.data.QC;
      this.contactTime = res.data.ContactTime;
    });
  },
  mounted(){
    document.documentElement.style.setProperty('--vh', `px`);
  },
  methods: {
    getV(event) {
      this.contactFormData.QC = event.target.value;
    },
    showRadioMsg(){
      this.submittedShow = true;
    },
    handleReset() {
      this.$refs.myForm.resetForm();
      this.contactFormData = [];
      this.image = this.file = "";
      this.contactFormData.QC = "請選取問題";
    },
		fileSelected(e) {
      this.fileUpLoadStatus = false;
			this.file = e.target.files.item(0);
			const files = e.target.files || e.dataTransfer.files;

			const reader = new FileReader();
			reader.addEventListener("load", this.imageLoaded);
			//reader.readAsDataURL(file);
			reader.readAsDataURL(this.file);

      //this.image = e.target.files.item(0).name;

      // console.log("img files===>", this.file);
      // console.log("img e.target.files===>", e.target.files);

      const imgFD = new FormData();
      imgFD.append('File[]', files[0]);
      axios({
        method: 'post',
        url: 'https://nkg-web-api.japaneast.cloudapp.azure.com/Admin/UP/?Name='+files[0].name+'&mid=0&mclass=hci&lang=tw&class=contact',
        data: imgFD,
        headers: {'Content-Type': 'multipart/form-data' }
      }).then(res => {
        this.contactFormData.RefImg = res.data;
        this.fileUpLoadStatus = "上傳完成！";
      })
      .catch(function (error) {
        console.log(error);
        this.fileUpLoadStatus = "上傳失敗！";
      });
		},
		// imageLoaded(e) {
		// 	this.image = e.target.result;
		// },
    // updateImg(e) {
    //   const file = e.target.files.item(0);
    //   const reader = new FileReader();
    //   reader.addEventListener("load", this.imageLoaded);
    //   reader.readAsDateURL(file);

    //   console.log("img file===>",file)
    // },
    imageLoaded(e) {
      if(this.file.type=="application/pdf"){
        this.image = 'https://kinpoweb.blob.core.windows.net/kinpo21/BC_TEST_BC_carbon_document-pdf(1).png';
      } else {
        this.image = e.target.result;
      }
    },
    // updateImg(e){
    //   let file = e.target.files[0];
    //   let formData = new FormData(); //建立form物件
    //   formData.append('file',file,file.RefImg);//通過append向form物件新增資料
    //   formData.append('chunk','0');//新增form表單中其他資料
    //   let config = {
    //     headers:{'Content-Type':'multipart/form-data'}
    //   }; //新增請求頭
    //   this.axios.post('https://nkg-web-api.japaneast.cloudapp.azure.com/Form/',formData,config)
    //   .then(res=>{
    //     console.log(res.data);
    //   })
    // },
    handleSubmit() {
      var formData = new FormData();

      formData.append("姓名", this.contactFormData.Name);
      formData.append("聯絡電話", this.contactFormData.Phone);
      formData.append("E-mail", this.contactFormData.Email);
      formData.append("問題類別", this.contactFormData.QC);
      formData.append("主旨", this.contactFormData.Title);
      formData.append("訊息內容", this.contactFormData.Content);
      formData.append("參考圖片", this.contactFormData.RefImg);
      formData.append("可聯絡時間", this.contactFormData.ContactTime);

      formData.append("mode", "add");

      formData.append(
        "data",
        "{" +
          '"class":"ContactUs",' +
          '"lang":"zh-TW",' +
          '"Name":"' +
          this.contactFormData.Name +
          '",' +
          '"Phone":"' +
          this.contactFormData.Phone +
          '",' +
          '"Email":"' +
          this.contactFormData.Email +
          '",' +
          '"QC":"' +
          this.contactFormData.QC +
          '",' +
          '"Title":"' +
          this.contactFormData.Title +
          '",' +
          '"Content":"' +
          this.contactFormData.Content +
          '",' +
          '"RefImg":"' +
          this.contactFormData.RefImg +
          '",' +
          '"ContactTime":"' +
          this.contactFormData.ContactTime +
          '",' +
          '"send_server":"' +
          this.sendServer +
          '",' +
          '"send_email":"' +
          this.QCToEmailTmp[this.contactFormData.QC] +
          '",' +
          '"send_name":"' +
          this.sendName +
          '",' +
          '"send_trigger":"' +
          this.sendTrigger +
          '",' +
          '"RandCode":"' +
          Math.random() +
          '"}'
      );

      axios({
        method: "post",
        url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        console.log("[contactFormData]" + res);

        this.isModalOpen = true;
        this.handleReset();

      console.log("姓名", this.contactFormData.Name);
      console.log("聯絡電話", this.contactFormData.Phone);
      console.log("E-mail", this.contactFormData.Email);
      console.log("問題類別", this.contactFormData.QC);
      console.log("主旨", this.contactFormData.Title);
      console.log("訊息內容", this.contactFormData.Content);
      console.log("參考圖片", this.contactFormData.RefImg);
      console.log("可聯絡時間", this.contactFormData.ContactTime);
    });
    }
  }
}
</script>

<style lang="scss">
.label-title.no-star::before {
  color: transparent;
}

.preview {
  border-radius: 12px;
  padding: .6rem;
  background: #ffe4c6;
  img {
    height: 100px;
    @media (max-width:320px) {
      width: 100%;
      height: auto;
    }
  }
  .text-success{
    color: $green !important;
  }
  .text-danger{
    color: $coral !important;
  }
}
.form__card{
  .text-dark{
    color: $gray !important;
  }
  .text-muted{
    color: #BDBDBD !important;
  }
}
</style>
