<template>
  <footer class="footer">
    <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/deco-footer.svg" class="img-fluid w-100" />
    <div class="copyright">
      <img
        src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/logo/logo_hcilove_icon.svg"
        class="footer-logo"
      />

      <span class="pe-2">許潮英慈善基金會</span>
      <br class="d-block d-md-none" />
      <span class="font-enUS">Hsu Chauing Social Welfare Charity Foundation</span>

      <div class="social-media mx-2 d-none d-md-inline-block">
        <a href="https://www.facebook.com/hci17611096/" target="_blank">
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-social_fb.svg"
          />
        </a>

        <a href="mailto:hcilove@hci.org.tw">
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-social_email.svg"
          />
        </a>

        <router-link :to="'/' + this.$route.params.lang + '/contact'">
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/icon-social_talk.svg"
          />
        </router-link>
      </div>
    </div>
    <nav class="footer-menu d-none d-md-block">
      <router-link
        :to="'/' + this.$route.params.lang + item.Link"
        v-for="item in footerMenu"
        :key="item.ID"
        >{{ item.Title }}</router-link
      >
    </nav>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      footerMenu: [],
    };
  },
  created() {
    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"HciFtMenu","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.footerMenu = res.data.data;
      console.log("footerMenu", this.footerMenu);
    });
  },
};
</script>
