<template>
  <div class="about__container">
    <!-- BANNER -->
    <template v-for="item in pagesBanner" :key="item.ID">
      <banner-single v-if="item.Pages == 'about'">
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
          ></div>
        </template>
        <template #operate>
          <div class="container">
            <h1 class="bannerbox__title mb-5">{{ item.Title }}</h1>
            <div class="anchor-group">
              <a href="#anchor1" class="switch-title active" v-smooth-scroll
                >成立的故事</a
              >
              <a href="#anchor2" class="switch-title" v-smooth-scroll
                >基金會大事記</a
              >
              <a href="#anchor3" class="switch-title" v-smooth-scroll
                >公開資料下載</a
              >
            </div>
          </div>
        </template>
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-white.svg"
            class="banner__mark img-fluid"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article>
      <!-- SECTION_1 -->
      <section id="sec1" class="sec pb-0">
        <div id="anchor1" ref="1"></div>
        <h2 class="sec__title">成立的故事</h2>

        <template v-for="item in pagesBanner" :key="item.ID">
          <div class="sec__content" v-if="item.Pages == 'about'">
            <div class="custom-content" v-html="item.Content"></div>

            <!--
          <div class="container">
            <div class="ratio ratio-16x9 custom-border mb-5">
              <iframe src="https://www.youtube.com/embed/lgTRqPMYjsA" title="YouTube video" allowfullscreen></iframe>
            </div>

            <p>延續金仁寶集團創辦人—老董事長許潮英先生的善心，許潮英慈善事業基金會持續關懷弱勢、善心助人，並整合集團企業的人力與資源，長期守護著獨居長輩、新住民子女、環境保護等面向，更聚焦於弱勢兒童，透過音樂、美術、體育等多元才藝，點亮孩子的生命與未來！許潮英先生是眾人熟知的資訊界前輩，當他在世時，只要聽聞災害、貧困人士的消息，就會帶著現金，領著家人衝到現場，將愛心與救助及時送達弱勢族群的手中，他的一生凡事總為他人著想，竭盡所能幫助需要的人；而為延續這份善心，當年結合了金寶電子工業股份有限公司、仁寶電腦工業股份有限公司、許潮英先生、許勝雄先生、蔡麗珠女士、許勝傑先生及其他十三位善心人士的捐助，因而於民國89年誕生了許潮英社會福利慈善事業基金會(以下簡稱許潮英基金會)。</p>
            <p>許潮英基金會籌備的初期適逢921大地震，因此隨即投入各項賑災工作，尤其對於流離失所的平地原住民，更是抱著「人飢己飢，人溺己溺」的精神，協助災民家園重建的工作。這麼多年來，台灣也歷經過多次的重大天災人禍，每次的援助行動，總會有基金會的身影，從一開始的急難救助，發展至近期的弱勢兒童才藝課程、圓夢飛翔計畫、獎助學金支持等，平均每年造福12,000位弱勢孩子，基金會以有限的資源、服務團隊的策略規劃及運用多元方式結合金仁寶集團員工的善心善行來傳遞溫暖，讓實現照顧弱勢的理念更加全面、且細水長流。許潮英基金會蔡麗珠董事長曾說過：「貧窮不應該阻擋孩子快樂的成長以及學習才藝的機會，我們期待幫助更多有需要的下一代！」</p>
          </div>

          <div class="container-fluid px-0 position-relative">
            <div class="container">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/about-1.jpg" class="img-fluid custom-border" alt="">
                </div>
                <div class="col-12 text-center my-5">
                  <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/about_text_img_1.svg" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/bg-wave-primary.svg" class="img-fluid w-100" alt="" style="position:absolute;bottom:0;z-index:-1;">
          </div>

          <div class="container pt-5">
            <p>因此，從2009年開始，基金會鎖定老董事長許潮英先生的家鄉桃園，與在地的學校合作，以「一校一特色」的方式，贊助美術、音樂、體育等多元面向的才藝課程軟硬體資源，讓弱勢孩童也有接受才藝課程的機會，<mark class="text-secondary">「看到孩子不但樂在其中的學習、增添信心，更成為人生道路上的心靈靠山是件令人感動的事!」</mark>蔡麗珠董事長這樣說到，所以基金會進一步加碼啟動《圓夢飛翔計畫》，透過老師挖掘具備天份的孩子，由金仁寶集團旗下企業員工共同認養，至今已經有超過近3,000位同仁認養約800多位孩子，持續深造才藝、圓夢飛翔！</p>
            <p class="text-center"><img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/about-2.jpg" class="img-fluid custom-border my-5" alt=""></p>
            <p>除了才藝課程、圓夢飛翔計畫外，基金會也持續進行各項公益專案，像是關注「新住民二代的文化認同」議題，幫助新住民二代連結母親的祖國文化；攜手國語日報推動「讀報教育實驗班」，藉由知識的力量來縮短偏鄉學童的城鄉差距；與相關企業成立的「志工社團」合作，就近服務鄰近社區的弱勢族群等，透過一系列的服務專案，將基金會的關懷如開枝散葉般傳播出去。</p>
            <p>許潮英基金會剛屆滿創立20周年，一直秉持著老董事長許潮英先生取之於社會，用之於社會的理念，默默支持需要幫助的人，這段期間的努力也逐漸開花結果、有目共睹，並期許未來能照亮更多的生命，正如同基金會的Logo，<mark class="text-secondary">讓愛從「心」出發，一起捧著我們的真心、善待他人把愛傳出去，讓 這股暖流源源不絕延續下去。</mark></p>
          </div>

          <div class="container-fluid px-0 position-relative bg-primary">
            <p class="text-center mb-0">
              <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/about_text_img_2.svg" class="img-fluid" alt="" style="position: relative; z-index: 9;">
            </p>
            <img src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-white.svg" class="img-fluid w-100" alt="" style="position:absolute;top:0;z-index:0;transform:rotate(180deg);">
          </div>
-->
          </div>
        </template>
      </section>

      <!-- SECTION_2 -->
      <section id="sec2" class="sec bg-primary pt-0">
        <div id="anchor2" ref="2"></div>
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
          class="img-fluid sec-deco-bottom"
        />
        <h2 class="sec__title">基金會大事記</h2>
        <div class="sec__content container">
          <tabs-slides :data_Y="memorabiliaList_Y" v-on:getYear="getYearM">
            <template #activetab>
              <div class="">
                <div class="row">
                  <div
                    class="col-md-6 mb-3 mb-md-5"
                    v-for="(item, index) in memorabiliaUpdate"
                    :key="index"
                  >
                    <figure>
                      <img-box class="custom-border mb-3">
                        <template #image>
                          <div class="imgbox__inner imgbox--ratio-5x3">
                            <div
                              class="imgbox__image"
                              :style="{
                                backgroundImage: 'url(\'' + item.Img + '\')',
                              }"
                            ></div>
                          </div>
                        </template>
                      </img-box>
                      <figcaption>{{ item.Title }}</figcaption>
                    </figure>
                  </div>
                  <p
                    class="text-center"
                    v-if="memorabiliaList.length > defaultLength"
                  >
                    <a class="btn btn-pill btn-light" @click="loadMore"
                      >瀏覽更多</a
                    >
                  </p>
                </div>
              </div>
            </template>
          </tabs-slides>
        </div>
      </section>

      <!-- SECTION_3 -->
      <section id="sec3" class="sec bg-secondary">
        <div id="anchor3" ref="3"></div>
        <h2 class="sec__title">公開資料下載</h2>
        <div class="sec__content container">
          <tabs-slides :data_Y="openData_Y" v-on:getYear="getYearO">
            <template #activetab>
              <table-rwd :fileList="openData" :itemClass="openDataClass" :itemName="'HciItems'"></table-rwd>
            </template>
          </tabs-slides>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";
import BannerSingle from "@/components/BannerSingle.vue";
import ImgBox from "@/components/ImgBox.vue";
import TableRwd from "@/components/TableRwd.vue";
import TabsSlides from "@/components/TabsSlides.vue";
//import ThumbnailTest from "@/components/ThumbnailTest.vue";

export default {
  components: {
    BannerSingle,
    ImgBox,
    TableRwd,
    TabsSlides,
    //ThumbnailTest,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      pagesBanner: [],
      activetab: null,
      serviceList: [],
      serviceResult: [],
      memorabiliaList: [],
      memorabiliaList_Y: [],
      memorabiliaListAll: [],
      openDataClass: [],
      openData_Y: [],
      openData: [],
      openDataAll: [],
      defaultLength: 4
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });
    // 頁面看板
    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"PagesBanner","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.pagesBanner = res.data.data;
    });

    // 大事記
    var FD2 = new FormData();
    FD2.append("mode", "raw_sct");
    FD2.append("data", '{"class":"Memorabilia","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD2,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.memorabiliaListAll = res.data.data;

      //算年份
      let DateTmp = [];
      res.data.data.forEach((v, k) => {
        DateTmp[k] = v.Y.slice(0, 4); // 取日期"年份"
      });
      this.memorabiliaList_Y = DateTmp.filter(
        (va, vb) => DateTmp.indexOf(va) === vb
      );
      // var sizes = [4, 4],i = 0,j = 0,chunks = [];
      // while (i < Object.keys(res.data.data).length){
      //   chunks.push(res.data.data.slice(i, i += sizes[j++ % sizes.length]));
      // }
      // this.memorabiliaList=chunks;
      this.getYearM(this.memorabiliaList_Y[0]);
    });

    // 公開資料下載
    var FD3 = new FormData();
    FD3.append("mode", "raw_sct");
    FD3.append("data", '{"class":"HciOpenData","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD3,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.openData = res.data.data;
      this.openDataClass = res.data.HciItems;

      let DateTmp = [];
      this.openData.forEach((v, k) => {
        DateTmp[k] = v.Date.slice(0, 4);
      });
      this.openData_Y = DateTmp.filter(
        (va, vb) => DateTmp.indexOf(va) === vb
      ).reverse();
      //console.log();

      this.openDataAll = this.openData;
      this.getYearO(this.openData_Y[0]);
    });
  },
  computed: {
    memorabiliaUpdate() {
      return this.memorabiliaList.slice(0, this.defaultLength);
    },
  },
  methods: {
    getYearM(getYearM) {
      this.memorabiliaList = this.memorabiliaListAll.filter(item => (item.Y.slice(0,4)==getYearM));
      this.activetab = getYearM;
      this.defaultLength = 4;
    },
    getYearO(getYearO) {
      this.openData = this.openDataAll.filter(
        (item) => item.Date.slice(0, 4) == getYearO
      );
      this.activetab = getYearO;
      // console.log(getYearO, this.openData);
      //中文字排序
      //this.openData = this.openDataAll.sort((C1,C2)=>C1["DonateItem"].localeCompare(C2["DonateItem"]));
    },
    loadMore() {
      if (this.defaultLength > this.memorabiliaList.length) return;
      this.defaultLength = this.defaultLength + 4;
    },
  },
};
</script>
